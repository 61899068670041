























































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import { StatusCode } from '@/services/HttpService/HttpService'
import { getDefaultFormItems } from '@/modules/user/resources'
import ConfirmEmailComponent from '@/components/common/ConfirmEmailComponent.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { Checkbox } from '@/components/molecules/Checkbox'
import { errorMessageRenderer } from '@/utils/utils'
import { Dropdown } from '@/components/molecules/Dropdown'
import { AcademicRank, Gender, OrganisationType } from '@/enums'
import { TextField } from '@/components/molecules/TextField'

const getDropdownItems = (items: any[], nullItem: string = '') => [
  { text: nullItem, value: null },
  ...items,
]

export default defineComponent({
  name: 'SignUpForm',
  components: {
    ConfirmEmailComponent,
    MarkdownComponent,
    TextField,
    Dropdown,
    Checkbox,
  },
  inject: ['enums'],
  data() {
    return {
      formItems: getDefaultFormItems(),
      email: '',
      password: '',
      confirmSignUpForm: {
        code: '',
      },
      signUpForm: {
        companyName: '',
        gender: null,
        academicRank: null,
        firstName: '',
        lastName: '',
        contactPhoneNumber: '',
        contactEmail: '',
        termsAndConditions: false,
        sendEmailNotifications: true,
        organisationType: null,
        registrationGoal: null,
      },
      confirmSignUpRules: {
        code: [
          {
            required: true,
            message: this.$t('signUp.form.validation.code.required'),
            trigger: 'blur',
          },
          {
            pattern: /^[0-9]{6}$/,
            message: this.$t('signUp.form.validation.code.pattern'),
            trigger: 'blur',
          },
        ],
      },
      signUpFormRules: {
        companyName: {
          required: true,
          message: this.$t('signUp.form.validation.companyName.required'),
          trigger: 'blur',
        },
        gender: {
          required: true,
          message: this.$t('signUp.form.validation.gender.required'),
          trigger: 'change',
        },
        firstName: {
          required: true,
          message: this.$t('signUp.form.validation.firstName.required'),
          trigger: 'blur',
        },
        lastName: {
          required: true,
          message: this.$t('signUp.form.validation.lastName.required'),
          trigger: 'blur',
        },
        contactPhoneNumber: {
          required: true,
          message: this.$t(
            'signUp.form.validation.contactPhoneNumber.required'
          ),
          trigger: 'blur',
        },
        contactEmail: [
          {
            required: true,
            message: this.$t('signUp.form.validation.email.required'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('signUp.form.validation.email.type'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('signUp.form.validation.password.required'),
            trigger: 'blur',
          },
          {
            pattern: /^.{6,}$/,
            message: this.$t('signUp.form.validation.password.pattern'),
            trigger: 'blur',
          },
        ],
        termsAndConditions: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: boolean, callback: any) => {
            if (!value) {
              const errorMessage = this.$t(
                'signUp.form.validation.termsAndConditions.required'
              ) as string
              return callback(new Error(errorMessage))
            }

            return callback()
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isSignedUp: 'getIsSignedUp',
    }),
    contactEmail: {
      get(): string | null {
        return this.signUpForm.contactEmail
      },
      set(email: string) {
        // force user to send lower-cased email
        return (this.signUpForm.contactEmail = email.toLowerCase())
      },
    },
    registrationGoalItems() {
      const items = [1, 2, 3, 4, 5].map((goal) => ({
        text: this.$t(`common.goals.${goal}`),
        value: goal,
      }))
      return getDropdownItems(items, this.$t('common.goals.placeholder'))
    },
    organisationTypeItems() {
      return Object.values(OrganisationType).map((type) => ({
        text: this.$t(
          `organisation.form.organisationType.options.${type.toLowerCase()}`
        ),
        value: type.toUpperCase(),
      }))
    },
    genderItems() {
      const items = Object.keys(Gender).map((gender) => ({
        text: this.$t(`common.gender.${gender}`),
        value: gender,
      }))
      return getDropdownItems(items, this.$t('common.selectNoGenderOption'))
    },
    academicRankItems() {
      const items = Object.keys(AcademicRank).map((rank) => ({
        text: this.$t(`common.academicRank.${rank}`),
        value: rank,
      }))
      return getDropdownItems(
        items,
        this.$t('common.selectNoAcademicRankOption')
      )
    },
  },
  created() {
    const query: { [param: string]: string } = this.$route.query

    const registrationGoal = query.goal
      ? this.$t(`common.goals.${query.goal}`)
      : null
    const organisationType = query.organisationType
      ? this.$t(
          `organisation.form.organisationType.options.${query.organisationType.toLowerCase()}`
        )
      : null

    this.signUpForm = Object.assign(this.signUpForm, {
      contactEmail: query.email,
      organisationType,
      registrationGoal,
    })
  },
  methods: {
    ...mapActions('auth', ['signUpCompany']),
    async onSignUp() {
      if (!(await this.$refs.signUpForm.validate())) {
        return
      }

      // Vuetify selects don't cope well with undefined,
      // so we have to convert back from null
      Object.keys(this.signUpForm).forEach((key) => {
        if (this.signUpForm[key] === null) {
          this.signUpForm[key] = undefined
        }
      })

      try {
        await this.signUpCompany(this.signUpForm)
      } catch (error: any) {
        if (error.statusCode === StatusCode.Conflict) {
          this.$router.push({
            name: 'marketplace',
            query: {
              invite: this.contactEmail,
            },
          })
        } else {
          throw new Error(errorMessageRenderer(error))
        }
      }
    },
  },
})
