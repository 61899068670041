














import { mapActions } from 'vuex'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'

export default {
  name: 'ConfirmEmailComponent',
  components: {
    MarkdownComponent,
  },
  inject: ['enums'],
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('auth', ['resendConfirmationCode']),
    onSignInLink() {
      this.$router.push({ name: 'signIn' })
    },
    async onConfirmationCode() {
      try {
        await this.resendConfirmationCode(this.email)
        await this.$message.success(this.$t('signUp.form.confirmText'))
      } catch (error) {
        this.$message.error(error.message)
      }
    },
  },
}
