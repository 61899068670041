
























import { config } from '@/config/config'
import SignUpForm from '@/components/page/Home/SignUpForm.vue'

export default {
  name: 'Hero',
  components: { SignUpForm },
  inject: ['enums'],
  data: () => {
    return {
      config,
    }
  },
  computed: {
    env() {
      return this.$env !== this.enums.Env.Production ? this.$env : null
    },
  },
  methods: {
    onSignIn() {
      this.$router.push({ name: 'signIn' })
    },
    onSignUp() {
      this.$router.push({ name: 'signUp' })
    },
  },
}
