














import LandingHeaderComponent from '@/components/common/LandingHeaderComponent/LandingHeaderComponent.vue'
import FooterComponent from '@/components/common/FooterComponent/FooterComponent.vue'
import Hero from '@/components/page/Home/Hero.vue'

export default {
  name: 'Home',
  components: {
    LandingHeaderComponent,
    Hero,
    FooterComponent,
  },
}
