















export default {
  name: 'LandingHeaderComponent',
  inject: ['enums'],
  computed: {
    env() {
      return this.$env !== this.enums.Env.Production ? this.$env : null
    },
  },
}
